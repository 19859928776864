<template>
  <div>
    <transition name="flixFadeIn">
      <div :key="open">
        <div v-if="!open">
          <div v-for="(data, key) in entry.data" :key="key">
            <div
              class="flix-list-group-item"
              v-html="getParseData(key, data)"
            ></div>
          </div>
        </div>
        <div v-if="open">
          <div class="flix-list-group-item">
            <label class="flix-html-label" for="note"
              ><flixIcon id="pen" />
              {{ $t('message.add', { name: $tc('message.note', 1) }) }}</label
            >
            <input
              id="note"
              type="text"
              class="flix-form-control"
              v-model="entryTemp[$tc('message.note', 1)]"
              :placeholder="$t('message.optional')"
            />
          </div>
          <assistentForm
            class="flix-list-group-item"
            :callback="
              (d, a) => {
                setData(d, a)
              }
            "
            :entry="entry"
            v-if="variables"
            :user="variables.user.md5_id"
            :id="entry.form"
          />
          <saveBtn
            :settings="{ block: true }"
            :onClick="
              () => {
                setSave()
              }
            "
          />
          <div
            v-if="saved"
            class="flix-alert flix-alert-success"
            v-html="$t('message.successfullSaved')"
          ></div>
        </div>
      </div>
    </transition>
    <div class="flix-list-group-item flix-text-right">
      <a
        href="#"
        @click.prevent="open = !open"
        class="flix-html-small flix-html-a"
        ><flixIcon id="wrench" />
        {{ $t('message.edit', { name: $tc('message.customer', 1) }) }}</a
      >
    </div>
  </div>
</template>
<script>
export default {
  components: {
    assistentForm() {
      return import('@/components/crm/addCustomer/assistentForm')
    }
  },
  props: {
    entry: Object
  },
  data() {
    return {
      open: false,
      variables: this.$getUserVariables(),
      entryTemp: JSON.parse(JSON.stringify(this.entry.data)),
      entryTempEmail: JSON.parse(JSON.stringify(this.entry.email)),
      entryTempTel: JSON.parse(JSON.stringify(this.entry.tel)),
      saved: false
    }
  },
  methods: {
    getParseData(key, data) {
      let r = '<b>' + key + ':</b> '
      let content = ''

      if (!data) {
        content = '---'
      } else if (typeof data.src !== 'undefined') {
        if (
          data.src.split('.pdf').length === 1 &&
          data.src.split('.mp3').length === 1
        ) {
          content += '<img src="' + data.src + '" class="previewImg">'
        }
        content +=
          '<a href="' + data.src + '" target="_blank" class="flix-html-a">' + data.src + '</a>'
      } else {
        content = data
      }

      r += content
      return r
    },

    setSave() {
      this.saved = true

      this.$flix_post({
        url: 'booking/change_data',
        data: {
          user: this.variables.user.md5_id,
          ID: this.entry.ID,
          data: this.entryTemp,
          email: this.entryTempEmail,
          tel: this.entryTempTel
        },
        callback: function (ret) {
          setTimeout(
            function () {
              this.entry.data = this.entryTemp
              this.entry.email = this.entryTempEmail
              this.entry.tel = this.entryTempTel
              this.$store.dispatch('changeEntryData', this.entry)
              this.saved = false
            }.bind(this),
            3000
          )
        }.bind(this)
      })
    },
    setData(d, a) {
      this.entryTemp = Object.assign(this.entryTemp, d)
      a.forEach(
        function (part) {
          if (part.subtype === 'email') {
            Object.keys(d).forEach(
              function (b) {
                if (b === part.label) {
                  this.entryTempEmail = d[b]
                }
              }.bind(this)
            )
          }
          if (part.subtype === 'tel') {
            Object.keys(d).forEach(
              function (b) {
                if (b === part.label) {
                  this.entryTempTel = d[b]
                }
              }.bind(this)
            )
          }
        }.bind(this)
      )
    }
  },
  mounted() {},
  computed: {}
}
</script>
<style lang="sass">
.previewImg
  width: 80px
  max-width: 100%
  margin-right: 10px
</style>
<style lang="sass" scoped>
.flix-list-group
  div
    .flix-list-group-item
      white-space: pre-line
      background-color: white
      font-size: 9pt
      word-break: normal
      word-wrap: break-word
      box-sizing: border-box
      &.flix-active
        background-color: darkgrey
        border-color: darkgrey
        &:hover
          background-color: darkgrey
          border-color: darkgrey
</style>
